<template>
    <div class="w-full block py-2 mb-0">
        <div :style="windowWidth < 600 ? 'height: ' + containerHeight + 'px' : ''" :class="data.ContainerClass">
            <div v-if="data.ImageContainer" :id="data.Id" :class="data.ImageContainer.ImageContainerClass" class="flex">
                <div :class="data.ImageContainer.ImageClass">
                    <img :src="data.ImageContainer.Image" alt="">
                    <div v-if="data.Text" :id="'text-' + data.Id" :style="(windowWidth < 600 ? data.Text.StyleMobile : data.Text.Style + '; top: ' + top)" :class="data.Text.Class">
                        <p class="mb-0" style="font-size: 39px;">{{ data.Text.Title }}</p>
                        <p>
                            {{ data.Text.Description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            top: 0,
            containerHeight: 0
        }
    },
    props: {
        data: {
            type: Object
        }
    },
    methods: {
        async setTop () {
            var e = this.data.Id
            await this.sleep(200)
            var parent = document.getElementById(e)
            var children = document.getElementById('text-' + e)
            var div = parent.clientHeight - children.clientHeight
            div = div / 2
            var persen = div * 100 / parent.clientHeight
            this.top = persen + '%'
            return persen + '%'
        },
        async calculateHeight () {
            await this.sleep(200)
            var e = this.data.Id
            var parent = document.getElementById(e)
            var imageValue = 0
            var children = document.getElementById('text-' + e)
            var top = this.data.Text.StyleMobile
            var split = top.split('top: ')
            var value = split[1]
            if (value.includes('%')) {
                value = value.replace('%', '')
                value = parseInt(value)
                value = value / 100
                imageValue = parent.clientHeight * value
                imageValue = parent.clientHeight - imageValue
            }
            var total = parent.clientHeight + children.clientHeight - imageValue
            this.containerHeight = total
            return total
        }
    },
    mounted () {
        this.setTop()
        this.calculateHeight()
    }
}
</script>
